<!--
 * @Author: wang peng
 * @Description:  新增/编辑
-->
<template>
  <ykc-form class="el-form-wrap">
    <!-- 新增 -->
    <div v-if="!isEdit">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="电桩名称" prop="name">
          <ykc-input
            placeholder="中文、英文、数字、字符-(1~30字数)，不可重复"
            maxlength="30"
            v-model="ruleForm.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          class="img-error"
          label="电桩编码"
          prop="code"
          bottomTip="温馨提示：此为电桩编码，终端编码会在电桩编码后增加两位递增编码，留空自动生成">
          <ykc-input
            placeholder="请输入电桩编码"
            maxlength="14"
            v-model="ruleForm.code"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="归属电站" prop="stationId" v-if="!isEdit">
          <ykc-dropdown
            :remote="true"
            :clearable="true"
            :data="brandListData"
            v-model="ruleForm.stationId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="品牌型号" prop="brandId" v-if="!isEdit">
          <div class="flex">
            <ykc-dropdown
              :remote="true"
              :clearable="true"
              :data="brandAndModelData"
              v-model="ruleForm.brandId"
              @change="selectChange"
              style="flex: 1; margin-right: 10px"></ykc-dropdown>
            <ykc-button @click="addModelFun">增型号</ykc-button>
          </div>
        </ykc-form-item>
        <ykc-form-item label="充电终端数量" v-if="!isEdit && pileGunNum !== null">
          <div class="flex">{{ this.pileGunNum }}</div>
        </ykc-form-item>
      </ykc-form>
    </div>
    <div v-else>
      <ykc-warm-tip
        show-icon
        type="info"
        description="温馨提示：编辑终端只支持名称和编码编辑，若要调整归属电站或型号，请删除重新创建!"></ykc-warm-tip>
      <ykc-form :model="ruleForm0" :rules="rules0" ref="ruleForm0" class="el-form-wrap">
        <ykc-form-item label="终端名称" prop="name">
          <ykc-input
            placeholder="中文、英文、数字、字符-(1~30字数)，不可重复"
            maxlength="30"
            v-model="ruleForm0.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="终端编码"
          prop="code"
          class="img-error"
          bottomTip="温馨提醒：终端编码请输入16位纯数字">
          <ykc-input
            placeholder="请输入电桩编码"
            maxlength="16"
            v-model="ruleForm0.code"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { directPage, brandModelPage, barrierPage } from '@/service/apis';

  export default {
    props: {
      gunId: {
        type: Number,
      },
      parName: {
        type: String,
      },
      parCode: {
        type: String,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        pileGunNum: null,
        brandAndModel: [], // 终端型号数据
        brandListData: [], // 归属电站数据
        brandAndModelData: [], // 终端型号数据
        ruleForm: {
          name: '',
          code: '',
          stationId: null,
          brandId: null,
          terminalNum: null,
        },
        rules: {
          name: [
            { required: true, message: '请输入电桩名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('电桩名称'),
            },
          ],
          code: [
            { required: false, message: '请输入电桩编码', trigger: 'blur' },
            { min: 14, max: 14, message: '长度为14位纯数字', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.decimalNumberType_14.regexp,
              message: regexpObj.regexp.input.decimalNumberType_14.errorTips.error(
                '电桩编码',
                '14位纯数字'
              ),
            },
          ],
          stationId: [{ required: true, message: '请选择归属电站', trigger: 'blur' }],
          brandId: [{ required: true, message: '请选择品牌型号', trigger: 'blur' }],
        },
        ruleForm0: {
          id: null,
          name: '',
          code: '',
        },
        rules0: {
          name: [
            { required: true, message: '请输入终端名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType_num1.regexp,
              message: regexpObj.regexp.input.inlineTextType_num1.errorTips.error('电桩名称'),
            },
          ],
          code: [
            { required: true, message: '请输入终端编码', trigger: 'blur' },
            { min: 16, max: 16, message: '长度为16位纯数字', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.decimalNumberType_16.regexp,
              message: regexpObj.regexp.input.decimalNumberType_16.errorTips.error(
                '电桩编码',
                '16位纯数字'
              ),
            },
          ],
        },
      };
    },
    created() {
      // 获取归属电站
      this.requestBrandList();
      this.requestBrandAndModelList();

      // 编辑回显
      if (this.gunId) {
        console.log('传参++++', this.gunId, this.parName, this.parCode);
        this.ruleForm0.id = this.gunId;
        this.ruleForm0.name = this.parName;
        this.ruleForm0.code = this.parCode;
      }
    },
    computed: {
      formData() {
        const formData = {
          name: this.ruleForm.name,
          code: this.ruleForm.code,
          stationId: this.ruleForm.stationId,
          brandId: this.ruleForm.brandId,
          terminalNum: this.pileGunNum,
        };
        return formData;
      },
      formData0() {
        const formData0 = {
          id: this.gunId,
          name: this.ruleForm0.name,
          code: this.ruleForm0.code,
        };
        return formData0;
      },
    },
    methods: {
      /**
       * 获取归属电站列表
       */
      requestBrandList() {
        barrierPage
          .findStationList({ keyword: '' })
          .then(res => {
            console.log('获取归属电站列表+++++', res);
            const params = res;
            params.map(item => {
              return this.brandListData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取品牌型号列表
       */
      requestBrandAndModelList() {
        brandModelPage
          .brandModelList({})
          .then(res => {
            console.log('获取品牌型号列表+++++', res);
            this.brandAndModel = res ?? [];
            res.map(item => {
              return this.brandAndModelData.push({
                id: item.id,
                name: item.modelName,
                pileGunNum: item.pileGunNum,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 选择型号
       * */
      selectChange(e) {
        console.log('选择型号+++', e);
        this.brandAndModel.map(item => {
          if (item.id === e) {
            this.pileGunNum = item.pileGunNum;
          }
          return this.pileGunNum;
        });
      },
      /**
       * @desc 添加型号
       * */
      addModelFun() {
        const routeUrl = this.$router.resolve({
          path: '/plantAssets/brandAndModel/List',
          query: {},
        });
        window.open(routeUrl.href, '_blank');
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise((resolve, reject) => {
          if (!this.isEdit) {
            this.$refs.ruleForm.validate(valid => {
              if (valid) {
                directPage
                  .dirAdd(this.formData)
                  .then(res => {
                    console.log('+++++', res);
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              }
            });
          } else {
            this.$refs.ruleForm0.validate(valid => {
              if (valid) {
                directPage
                  .dirUpdate(this.formData0)
                  .then(res => {
                    console.log('+++++', res);
                    this.$message({
                      message: '编辑成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              }
            });
          }
        });
      },
    },
  };
</script>
