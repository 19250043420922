<!--
 * @Author: wang peng
 * @Description: 互联电桩列表
-->
<template>
  <scroll-layout class="interconnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'asset:station:conpile:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
            <ykc-button v-rbac="'asset:station:conpile:custom'" type="plain" @click="customColumns">
              自定义列
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
      <!--自定义列弹窗--start -->
      <ykc-operation-dialog
        title="自定义列"
        center
        show-footer
        :show.sync="show1"
        :before-close="onCLose"
        :before-cancel="beforeCancel"
        :before-confirm="beforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            ref="intCustomForm"
            label-width="80px"
            class="intCustomForm"
            :model="intCustomForm"
            :rules="intCustomRules">
            <el-form-item label="列选项" prop="checkOption">
              <ykc-checkbox
                :configSet="{
                  label: 'label',
                  value: 'value',
                }"
                :data="customData"
                v-model="intCustomForm.checkOption"
                @change="checkboxChange"></ykc-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
      <!--自定义列弹窗--end -->
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { localSetItem, localGetItem, offlineExport, code } from '@/utils';
  import { barrierPage, directPage } from '@/service/apis';

  export default {
    name: 'interconnectionPileList',
    inject: ['reload'],
    data() {
      return {
        ruleId: '',
        show1: false,
        dictionary: {}, // 字典数据
        brandListData: [], // 归属电站数据
        OperatorListData: [], // 归属商户数据
        searchParams: {
          code: '',
          name: '',
          stationId: '',
          stationOperator: '',
          statusList: [],
          startStatus: '',
        },
        intCustomForm: {
          checkOption: [0, 1, 2, 3, 4, 5],
        },
        customData: [
          {
            value: 0,
            label: '终端编码',
          },
          {
            value: 1,
            label: '终端名称',
          },
          {
            value: 2,
            label: '归属电站',
          },
          {
            value: 3,
            label: '终端启停',
          },
          {
            value: 4,
            label: '工作状态',
          },
          {
            value: 5,
            label: '归属商户',
          },
          {
            value: 6,
            label: '投用时间',
          },
        ],
        intCustomRules: {
          checkOption: [{ required: true, message: '请至少选择一个列', trigger: 'blue' }],
        },
        tableTitle: '互联电桩列表', // 列表表格
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { id: 0, label: '终端编码', prop: 'gunCode', minWidth: '200px' },
          { id: 1, label: '终端名称', prop: 'gunName', minWidth: '200px' },
          { id: 2, label: '归属电站', prop: 'stationName', minWidth: '200px' },
          { label: '归属商户', prop: 'operatorName', minWidth: '200px', id: 3 },
          {
            label: '工作状态',
            prop: 'gunStatus',
            minWidth: '120px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = [
                  {
                    color: 'status-black',
                    name: '离线',
                  },
                  {
                    color: 'status-red',
                    name: '故障',
                  },
                  {
                    color: 'status-green',
                    name: '空闲',
                  },
                  {
                    color: 'status-blue',
                    name: '充电',
                  },
                ][row.gunStatus];

                if (!cls) {
                  return '一';
                }

                return <span class={['userStatus', cls.color]}>{cls.name}</span>;
              },
            },
            id: 4,
          },
          {
            label: '终端启停',
            prop: 'startStatus',
            minWidth: '120px',
            scopedSlots: {
              default: ({ row }) => {
                return row.startStatus === 1 ? (
                  <span class={['userStatus', 'status-blue']}>启用</span>
                ) : (
                  <span class={['userStatus', 'status-red']}>停用</span>
                );
              },
            },
            id: 5,
          },
          { label: '投运时间', prop: 'userTime', minWidth: '200px', id: 6 },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      const intCustomForm = localGetItem('intCustomForm');

      if (intCustomForm) {
        this.intCustomForm.checkOption = intCustomForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return intCustomForm.includes(item.id);
        });
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![6].includes(item.id);
        });
      }

      this.requestBrandList();
      this.requestOperatorList();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'code',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcInput',
            key: 'name',
            label: '终端名称',
            placeholder: '请输入终端名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '归属电站',
            placeholder: '请选择归属电站',
            data: this.brandListData,
          },
          {
            comName: 'YkcDropdown',
            key: 'stationOperator',
            label: '归属商户',
            placeholder: '请选择归属商户',
            data: this.OperatorListData,
          },
          {
            multiple: true,
            clearable: true,
            comName: 'YkcDropdown',
            key: 'statusList',
            label: '工作状态',
            placeholder: '请选择工作状态',
            data: [
              { id: '0', name: '离线' },
              { id: '1', name: '故障' },
              { id: '2', name: '空闲' },
              { id: '3', name: '充电中' },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'startStatus',
            label: '启停状态',
            placeholder: '请选择启停状态',
            data: [
              all,
              ...[
                { id: '0', name: '停用' },
                { id: '1', name: '启用' },
              ],
            ],
          },
        ];
      },
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('asset:station:conpile:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 1}
                onClick={() => {
                  this.doAbleActivity(row);
                }}>
                启用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:conpile:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 0}
                onClick={() => {
                  this.doDisableActivity(row);
                }}>
                停用
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'station_pile_export',
            jsonNode: {
              stationType: 2,
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.show1 = true;
      },
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.intCustomForm.checkOption = e;
        console.log('this.intCustomForm+++', this.intCustomForm);
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.show1 = false;
      },
      /**
       * 弹窗关闭
       * */
      beforeCancel(done) {
        done();
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.intCustomForm);
        this.$refs.intCustomForm.validate(valid => {
          if (valid) {
            localSetItem('intCustomForm', JSON.stringify(this.intCustomForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * @desc 单个启用
       * */
      doAbleActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认启用该电桩？',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            directPage
              .intStartSwitch({ gunIdList: [row.gunId] || '', startStatus: 1 })
              .then(res => {
                console.log(res);
                this.$message.success('电桩启用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 单个停用
       * */
      doDisableActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '点击确认将停用该电桩',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            directPage
              .intStartSwitch({ gunIdList: [row.gunId] || '', startStatus: 0 })
              .then(res => {
                console.log(res);
                this.$message.success('电桩停用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 获取归属电站列表
       */
      requestBrandList() {
        barrierPage
          .findStationList({ type: 2 })
          .then(res => {
            console.log('获取归属电站列表+++++', res);
            const params = res;
            params.map(item => {
              return this.brandListData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        directPage
          .userOperatorList({ operatorType: '2' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        directPage
          .intFindByPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .interconnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }
      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }
  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }
</style>
